import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { initializeIcons } from "@uifabric/icons";

import { MainComponent } from "./Components/MainComponent";
import { Loader } from "./Components/LoaderComponent";
import { Test } from "./Components/Test";
const request = require("request");

initializeIcons();

ReactDOM.render(<Loader hideDialog={false} />, document.getElementById("root"));

let params = new URL(window.location.href).searchParams;
let mapId = params.get("mapId");

var options = {
  method: "GET",
  // url: "https://dev.tntsoftware.ro/api/MapPins",
  url: "https://dev.tntsoftware.ro/api/Maps",
  headers: {
    "content-type": "application/json",
    authentication: "key=2eb9bf44-3bfd-47e7-8899-b71871de7d52",
    // maptype: "1",
    mapId: mapId,
  },
  // body: {
  //   mapId: "99"
  // }
};

request(options, (error: any, response: any, body: any) => {
  if (error) throw new Error(error);

  document.title = JSON.parse(body).name;

  ReactDOM.render(
    <MainComponent body={JSON.parse(body)} />,
    document.getElementById("root")
  );
});

//140 pt teste

// var body = {
//   name: "Selimbar",
//   zoom: 14,
//   type: "Investitii",
//   boundariesFileURL:
//     "https://app.startmanager.ro/Fisiere\\Files\\4406045\\Maps\\99\\ComunaSelimbar_637182259359363414.kml",
//   lat: 45.77281175,
//   lng: 24.1870255,
//   categories: [
//     {
//       name: "Drumuri",
//       id: 38,
//       iconURL:
//         "https://app.citymanager.online/Fisiere/MapsIcons/pin-default.png",
//       elements: [
//         {
//           featuredImage:
//             "https://app.citymanager.online/Fisiere/MapsIcons/no-image.png",
//           title: "Amenajare santier\r\n",
//           location: {
//             lat: -1,
//             lng: 0,
//           },
//           address: "Sibiu Triaj, Sibiu, România",
//           KMLFileURL:
//             "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\10\\Pin2_637182261538277459.kml",
//           details: {
//             metadata: [
//               {
//                 id: 9,
//                 type: "highlight | table | row",
//                 content: {
//                   title: "Executant",
//                   body: "SC Paconstruct SRL",
//                 },
//               },
//               {
//                 id: 10,
//                 type: "highlight | table | row",
//                 content: {
//                   title: "Data de incepere a lucrarii",
//                   body: " 23.07.2018",
//                 },
//               },
//               {
//                 id: 11,
//                 type: "highlight | table | row",
//                 content: {
//                   title: "Valoare  investitiei din contracat  (cu TVA)",
//                   body: "3202483.82",
//                 },
//               },
//               {
//                 id: 12,
//                 type: "highlight | table | row",
//                 content: {
//                   title: "Valoare totala a investitiei (cu TVA)",
//                   body: "3724386.97",
//                 },
//               },
//             ],
//             Images: [],
//           },
//           FilterValues: {
//             PinFilterValues: [
//               {
//                 filterName: "Valoare",
//                 filterProperty: "valoare",
//                 value: "0",
//               },
//               {
//                 filterName: "Stadiu investitie",
//                 filterProperty: "stadiuinvestitie",
//                 value: "2",
//                 key: "In Executie",
//               },
//               {
//                 filterName: "Data Inceput",
//                 filterProperty: "dataInceput",
//                 value: "2020-01-01",
//               },
//             ],
//           },
//         },
//         {
//           featuredImage:
//             "https://app.startmanager.ro/Fisiere\\Files\\4406045\\Maps\\Pins\\26\\82552123_2610227565770097_4069447891956006912_n (1).jpg637152140076764279.jpg",
//           title:
//             "Continuarea amenajarii Strada Pictor Nicolae Brana, comuna Selimbar",
//           location: {
//             lat: 45.782875,
//             lng: 24.182186,
//           },
//           address: "Strada Nicolae Brana 50, Șelimbăr 557260, România",
//           KMLFileURL:
//             "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\26\\Pin1_637182261316135395.kml",
//           details: {
//             metadata: [
//               {
//                 id: 21,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Data de incepere a lucrarii",
//                   body: "23.07.2018",
//                 },
//               },
//               {
//                 id: 22,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Executant",
//                   body: "SC Paconstruct SRL",
//                 },
//               },
//               {
//                 id: 23,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Sursa de finantare",
//                   body: "Buget local",
//                 },
//               },
//               {
//                 id: 24,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Valoare investitiei din contract (cu TVA)",
//                   body: "3202483.82",
//                 },
//               },
//               {
//                 id: 28,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Valoarea totala a investitiei (cu TVA)",
//                   body: "3724386.97",
//                 },
//               },
//             ],
//             Images: [
//               "https://app.startmanager.ro/Fisiere\\Files\\4406045\\Maps\\Pins\\26\\82552123_2610227565770097_4069447891956006912_n (1).jpg637152140076764279.jpg",
//               "https://app.startmanager.ro/Fisiere\\Files\\4406045\\Maps\\Pins\\26\\logo tnt.png637152140077858113.png",
//               "https://app.startmanager.ro/Fisiere\\Files\\4406045\\Maps\\Pins\\26\\Screenshot 2019-07-08 14.44.12.png637152140078639536.png",
//             ],
//           },
//           FilterValues: {
//             PinFilterValues: [
//               {
//                 filterName: "Valoare",
//                 filterProperty: "valoare",
//                 value: "10001",
//               },
//               {
//                 filterName: "Stadiu investitie",
//                 filterProperty: "stadiuinvestitie",
//                 value: "3",
//                 key: "Terminata",
//               },
//               {
//                 filterName: "Data Inceput",
//                 filterProperty: "dataInceput",
//                 value: "2020-03-21",
//               },
//             ],
//           },
//         },
//       ],
//     },
//     {
//       name: "Turism",
//       id: 39,
//       iconURL:
//         "https://app.citymanager.online/Fisiere/MapsIcons/pin-default.png",
//       elements: [
//         {
//           featuredImage:
//             "https://app.citymanager.online/Fisiere/MapsIcons/no-image.png",
//           title: "Hotel 3*",
//           location: {
//             lat: 45.768515,
//             lng: 24.190818,
//           },
//           address: null,
//           details: {
//             metadata: [
//               {
//                 id: 29,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "S.C. Turism SRL Selimbar",
//                   body: "capital social 1200 lei",
//                 },
//               },
//               {
//                 id: 30,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "S.C. Andiamo SRL",
//                   body: "capital social 200 lei",
//                 },
//               },
//               {
//                 id: 31,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Capacitate turisti",
//                   body:
//                     "200 paturi in 45 camere duble, 10 camere triple si 10 apartamente",
//                 },
//               },
//               {
//                 id: 32,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Facilitati masa",
//                   body:
//                     "2 restaurante a la carte, meniu international, 1 salon mic dejun bufet",
//                 },
//               },
//             ],
//             Images: [
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\28\\slider-Selimbar2_637160895562972243.jpg",
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\28\\images_637160895570472048.jpg",
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\28\\selimbar-3-678x381-1_637160895571253547.jpg",
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\28\\82552123_2610227565770097_4069447891956006912_n (1)_637160898871368979.jpg",
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\28\\stema-romaniei-logo-5261C16723-seeklogo.com_637160898872150807.png",
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\28\\logo tnt_637160898873251811.png",
//             ],
//           },
//           FilterValues: {
//             PinFilterValues: [
//               {
//                 filterName: "Valoare",
//                 filterProperty: "valoare",
//                 value: "6580",
//               },
//               {
//                 filterName: "Stadiu investitie",
//                 filterProperty: "stadiuinvestitie",
//                 value: "1",
//                 key: "In Proiect",
//               },
//               {
//                 filterName: "Data Inceput",
//                 filterProperty: "dataInceput",
//                 value: "2020-04-18",
//               },
//             ],
//           },
//         },
//         {
//           featuredImage:
//             "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\29\\selimbar-3-678x381-1_637158236660659132.jpg",
//           title: "Parc distractii",
//           location: {
//             lat: 45.760747,
//             lng: 24.187435,
//           },
//           address: null,
//           details: {
//             metadata: [
//               {
//                 id: 33,
//                 type: "highlight | columns | row",
//                 content: {
//                   title: "Numar de locuri",
//                   body: "1000 sezlonguri, 300 scaune masaj, 20 fotolii",
//                 },
//               },
//             ],
//             Images: [
//               "https://app.citymanager.online/Fisiere\\Files\\4406045\\Maps\\Pins\\29\\selimbar-3-678x381-1_637158236660659132.jpg",
//             ],
//           },
//           FilterValues: {
//             PinFilterValues: [
//               {
//                 filterName: "Valoare",
//                 filterProperty: "valoare",
//                 value: "4500000",
//               },
//               {
//                 filterName: "Stadiu investitie",
//                 filterProperty: "stadiuinvestitie",
//                 value: "3",
//                 key: "Terminata",
//               },
//               {
//                 filterName: "Data Inceput",
//                 filterProperty: "dataInceput",
//                 value: "2020-05-11",
//               },
//             ],
//           },
//         },
//       ],
//     },
//   ],
//   filters: [
//     {
//       name: "Valoare",
//       property: "valoare",
//       type: "intervalInteger",
//       showInPinWindow: true,
//       min: 6580,
//       max: 4500000,
//     },
//     {
//       name: "Stadiu investitie",
//       property: "stadiuinvestitie",
//       type: "list",
//       showInPinWindow: true,
//       options: [
//         {
//           key: "In Executie",
//           value: "2",
//         },
//         {
//           key: "Terminata",
//           value: "3",
//         },
//         {
//           key: "In Proiect",
//           value: "1",
//         },
//       ],
//     },
//     {
//       name: "Data Inceput",
//       property: "dataInceput",
//       type: "date",
//       showInPinWindow: true,
//       min: "2020-03-05",
//       max: "2020-06-01",
//     },
//   ],
// };

// ReactDOM.render(<MainComponent body={body} />, document.getElementById("root"));

// ReactDOM.render(<Test />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
