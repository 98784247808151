import * as React from "react";
import { InfoWindow } from "react-google-maps";
import { Label } from "office-ui-fabric-react/lib/Label";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { DetailsWindow } from "./DetailsWindow";
import "../CSS/info-window.css";
import Img from "react-image";
import { Loader } from "../LoaderComponent";
import { CircularProgress } from "@material-ui/core";
import { MainComponent } from "../MainComponent";

interface IProps {
  closeCallback: any;
  marker: any;
}

interface IState {
  hideDialog: boolean;
}

export class InfoWindowCustom extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hideDialog: true,
    };
  }

  public render() {
    let number = Math.floor(
      12 / this.props.marker.FilterValues.PinFilterValues.length
    );

    return (
      <div>
        <DetailsWindow
          hideDialog={this.state.hideDialog}
          marker={this.props.marker}
          closeCallback={this._closeDialog}
        />
        <InfoWindow onCloseClick={this._onWindowClose}>
          <div style={{ overflow: "hidden", margin: 10, minWidth: 600 }}>
            <div className={"separated"}>{this.props.marker.title}</div>

            <div className="row">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="row" style={{ marginBottom: 40 }}>
                  {this.props.marker.FilterValues.PinFilterValues.map(
                    (filter: any, i: number) => {
                      if (MainComponent.allowedFilters[filter.filterProperty]) {
                        return (
                          <div
                            className={
                              "col-sm-" +
                              number +
                              " col-md-" +
                              number +
                              " col-lg-" +
                              number +
                              " brdRight"
                            }
                          >
                            <div style={{ display: "" }}>
                              <Label
                                className={"colTitle"}
                                style={{ margin: 0, padding: 0 }}
                              >
                                {filter.filterName}
                              </Label>
                              <Label
                                className={"colValue"}
                                style={{ margin: 0, padding: 0 }}
                              >
                                {filter.key == undefined
                                  ? filter.value
                                  : filter.key}
                              </Label>
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
                <div className="row">
                  <div className="col">
                    <DefaultButton
                      text={"Vezi Detalii"}
                      onClick={this._openDialog}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <Img
                  //style={{ height: 140, objectFit: "cover", width: "100%" }}
                  className="thumb"
                  src={[
                    this.props.marker.featuredImage,
                    require("../../assets/notfound.png"),
                  ]}
                  loader={<CircularProgress />}
                />
              </div>
            </div>
          </div>
        </InfoWindow>
      </div>
    );
  }

  private _openDialog = () => {
    this.setState({
      hideDialog: false,
    });
  };

  private _closeDialog = () => {
    this.setState({
      hideDialog: true,
    });
  };

  private _onWindowClose = () => {
    this.props.closeCallback();
  };
}
