import * as React from "react";
import { DetailsWindow } from "./DetailsWindow";
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import "../CSS/slide-pane.css";
import { Label, DefaultButton, Icon } from "office-ui-fabric-react";
import Img from "react-image";
import { CircularProgress } from "@material-ui/core";

interface IProps {
  closeCallback: any;
  marker: any;
}

interface IState {
  hideDialog: boolean;
}

export class SlidingPaneCustom extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hideDialog: true,
    };
  }

  public render() {
    let number = Math.floor(
      12 / this.props.marker.FilterValues.PinFilterValues.length
    );

    return (
      <div>
        <DetailsWindow
          hideDialog={this.state.hideDialog}
          marker={this.props.marker}
          closeCallback={this._closeDialog}
        />
        <SlidingPane
          onAfterOpen={() => {
            let margin = window.innerHeight - 534;
            document
              .getElementsByClassName("slide-pane_from_bottom")[0]
              .setAttribute("style", `margin-top: ${margin}px`);
          }}
          isOpen={true}
          from="bottom"
          width="100%"
          onRequestClose={this.props.closeCallback}
        >
          <Icon
            style={{ float: "right" }}
            iconName="ChromeClose"
            onClick={this.props.closeCallback}
          />
          <div className={"separated"}>{this.props.marker.title}</div>

          <div className="row" style={{ marginBottom: 10 }}>
            {this.props.marker.FilterValues.PinFilterValues.map(
              (filter: any, i: number) => {
                return (
                  <div
                    className={
                      "col-sm-" +
                      number +
                      " col-md-" +
                      number +
                      " col-lg-" +
                      number +
                      " separated"
                    }
                  >
                    <div style={{ display: "" }}>
                      <Label
                        className={"colTitle"}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {filter.filterName}
                      </Label>
                      <Label
                        className={"colValue"}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {filter.key == undefined ? filter.value : filter.key}
                      </Label>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col">
              <Img
                //style={{ height: 140 }}
                className="thumb"
                src={[
                  this.props.marker.featuredImage,
                  require("../../assets/notfound.png"),
                ]}
                loader={<CircularProgress />}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <DefaultButton text={"Vezi Detalii"} onClick={this._openDialog} />
            </div>
          </div>
        </SlidingPane>
      </div>
    );
  }

  private _openDialog = () => {
    this.setState({
      hideDialog: false,
    });
  };

  private _closeDialog = () => {
    this.setState({
      hideDialog: true,
    });
  };
}
