import * as React from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  KmlLayer,
} from "react-google-maps";
import { ClusterMarkersComponent } from "./ClusterMarkersComponent";
import { ClusterKML } from "./ClusterKML";

interface IProps {
  markers: any[];
  zoom: number;
  lat: number;
  lng: number;
  kmlUrl: string;
}
interface IState {
  markers: any[];

  visible: boolean;
  zoom: number;
  lat: number;
  lng: number;
  height: number;
  kmlUrl: string;
}

let clasa: any;
let _map: any;

export class Map extends React.Component<IProps, IState> {
  private _markerCluster: any;
  private _markerKML: any;

  constructor(props: IProps) {
    super(props);

    clasa = this;

    this.state = {
      markers: props.markers,
      visible: true,
      zoom: this.props.zoom,
      lat: this.props.lat,
      lng: this.props.lng,
      height: window.innerHeight,
      kmlUrl: this.props.kmlUrl,
    };
  }

  public componentDidMount() {
    let object = document.getElementById("header");
    let headerHeight: number = 0;

    if (object != null) {
      headerHeight = object.offsetHeight;
    }

    this.setState({ height: window.innerHeight - headerHeight }, () => {
      this.forceUpdate();
    });
  }

  public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (
      this.state.height != nextState.height &&
      this.state.lat != 0 &&
      this.state.lng != 0 &&
      this.state.zoom != 0
    ) {
      return true;
    }

    return false;
  }

  public componentWillReceiveProps(props: IProps) {
    this.setState({ markers: props.markers });

    if (this._markerCluster != undefined) {
      this._markerCluster.setState({ markers: props.markers });
    }
    if (this._markerKML != undefined) {
      this._markerKML.setState({ markers: props.markers });
    }

    // if (
    //   this.state.zoom == 0 ||
    //   this.state.lat == 0 ||
    //   this.state.lng == 0 ||
    //   this.state.kmlUrl == ""
    // ) {
    //   console.log("In props", props.zoom, props.lat, props.lng, props.kmlUrl);
    //   console.log("In props map", _map);

    //   this.setState({
    //     markers: props.markers,
    //     zoom: props.zoom,
    //     lat: props.lat,
    //     lng: props.lng,
    //     kmlUrl: props.kmlUrl
    //   });

    //   if (_map != null) {
    //     _map.forceUpdate();
    //   }
    // }
  }

  public render() {
    console.log(
      "Folosite",
      this.state.zoom,
      this.state.lat,
      this.state.lng,
      this.state.kmlUrl
    );

    const GoogleMapExample = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyDWM9_q_-F_3dWiZRhIPRmXH-1lGERjy0M&v=3.exp&libraries=geometry,drawing,places&language=ro&region=RO",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: this.state.height }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )(() => (
      <GoogleMap
        ref={(map) => (_map = map)}
        defaultZoom={this.state.zoom}
        defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
      >
        <ClusterMarkersComponent
          ref={(marker: any) => (this._markerCluster = marker)}
          markers={this.state.markers}
        />
        <ClusterKML
          ref={(marker: any) => (this._markerKML = marker)}
          markers={this.state.markers}
        />
        {this.state.kmlUrl != null && (
          <KmlLayer
            url={this.state.kmlUrl}
            options={{ preserveViewport: false }}
          />
        )}
      </GoogleMap>
    ));

    return <GoogleMapExample />;
  }
}

window.addEventListener("resize", function (event) {
  let object = document.getElementById("header");
  let height: number = 0;

  if (object != null) {
    height = object.offsetHeight;
  }

  if (_map != null) {
    clasa.setState({
      height: window.innerHeight - height,
      zoom: _map.getZoom(),
      lat: _map.getCenter().lat(),
      long: _map.getCenter().lng(),
    });
  }
});
