import * as React from "react";
import { Dialog } from "office-ui-fabric-react/lib/Dialog";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

interface IProps {
    hideDialog: boolean;
}

interface IState {
    hideDialog: boolean;
}

export class Loader extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            hideDialog: this.props.hideDialog
        };
    }

    public componentWillReceiveProps(props: IProps) {
        this.setState({ hideDialog: props.hideDialog });
    }

    public render() {
        return (
            <div style={{ zIndex: 100 }}>
                <Dialog
                    hidden={this.state.hideDialog}
                    modalProps={{ isBlocking: true }}
                >
                    <Spinner size={SpinnerSize.large} label="Loading ..." />
                </Dialog>
            </div>
        );
    }
}
