import * as React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
  ResponsiveMode
} from "office-ui-fabric-react/lib/Dialog";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
// @ts-ignore
import Carousel from "react-images";

import "../CSS/details-window.css";
import { Label } from "office-ui-fabric-react";

interface IProps {
  hideDialog: boolean;
  marker: any;
  closeCallback: any;
}

interface IState {
  hideDialog: boolean;

  currentIndex: number;
}

export class DetailsWindow extends React.Component<IProps, IState> {
  private _images: any = [];

  constructor(props: IProps) {
    super(props);

    this.props.marker.details.Images.map((image: any) => {
      this._images.push({
        src: image
      });
    });

    this.state = {
      hideDialog: this.props.hideDialog,
      currentIndex: 0
    };
  }

  public componentWillReceiveProps(props: IProps) {
    this.setState({
      hideDialog: props.hideDialog
    });
  }

  public render() {
    return (
      <div style={{ borderRadius: 5 }}>
        <Dialog
          hidden={this.state.hideDialog}
          onDismiss={this._closeDialog}
          responsiveMode={ResponsiveMode.medium}
          minWidth={1000}
          dialogContentProps={{
            type: DialogType.normal,
            title: this.props.marker.title
          }}
        >
          {this._filterRows()}

          {this._table()}

          {this._images.length != 0 ? (
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                {this._columns()}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div style={{ marginBottom: 20 }}>
                  <Carousel
                    currentIndex={this.state.currentIndex}
                    views={this._images}
                  />
                </div>

                {this._gallery()}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                {this._columns()}
              </div>
            </div>
          )}

          <DialogFooter>
            <DefaultButton onClick={this._closeDialog} text="Inchide" />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  private _filterRows = () => {
    let rowNumbers = Math.floor(
      this.props.marker.FilterValues.PinFilterValues.length / 3
    );

    let rows = [];
    for (let i = 0; i < rowNumbers; i++) {
      rows.push(i * 3);
    }

    rows.map(startIndex => {
      return (
        <div className="row rowTable">
          <div className="col-sm-4 col-md-4 col-lg-4 brdRight">
            <div style={{ display: "" }}>
              <Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
                {
                  this.props.marker.FilterValues.PinFilterValues[startIndex]
                    .filterName
                }
              </Label>
              <Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
                {this.props.marker.FilterValues.PinFilterValues[startIndex]
                  .key != undefined
                  ? this.props.marker.FilterValues.PinFilterValues[startIndex]
                      .key
                  : this.props.marker.FilterValues.PinFilterValues[startIndex]
                      .value}
              </Label>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 brdRight">
            <div style={{ display: "" }}>
              <Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
                {
                  this.props.marker.FilterValues.PinFilterValues[startIndex + 1]
                    .filterName
                }
              </Label>
              <Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
                {this.props.marker.FilterValues.PinFilterValues[startIndex + 1]
                  .key != undefined
                  ? this.props.marker.FilterValues.PinFilterValues[
                      startIndex + 1
                    ].key
                  : this.props.marker.FilterValues.PinFilterValues[
                      startIndex + 1
                    ].value}
              </Label>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4">
            <div style={{ display: "" }}>
              <Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
                {
                  this.props.marker.FilterValues.PinFilterValues[startIndex + 2]
                    .filterName
                }
              </Label>
              <Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
                {this.props.marker.FilterValues.PinFilterValues[startIndex + 2]
                  .key != undefined
                  ? this.props.marker.FilterValues.PinFilterValues[
                      startIndex + 2
                    ].key
                  : this.props.marker.FilterValues.PinFilterValues[
                      startIndex + 2
                    ].value}
              </Label>
            </div>
          </div>
        </div>
      );
    });

    switch (this.props.marker.FilterValues.PinFilterValues.length % 3) {
      case 0: {
        return;
      }
      case 1: {
        return (
          <div className="row rowTable">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div style={{ display: "" }}>
                <Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
                  {
                    this.props.marker.FilterValues.PinFilterValues[
                      3 * rowNumbers
                    ].filterName
                  }
                </Label>
                <Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
                  {this.props.marker.FilterValues.PinFilterValues[
                    3 * rowNumbers
                  ].key != undefined
                    ? this.props.marker.FilterValues.PinFilterValues[
                        3 * rowNumbers
                      ].key
                    : this.props.marker.FilterValues.PinFilterValues[
                        3 * rowNumbers
                      ].value}
                </Label>
              </div>
            </div>
          </div>
        );
      }
      case 2: {
        return (
          <div className="row rowTable">
            <div className="col-sm-6 col-md-6 col-lg-6 brdRight">
              <div style={{ display: "" }}>
                <Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
                  {
                    this.props.marker.FilterValues.PinFilterValues[
                      3 * rowNumbers
                    ].filterName
                  }
                </Label>
                <Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
                  {this.props.marker.FilterValues.PinFilterValues[
                    3 * rowNumbers
                  ].key != undefined
                    ? this.props.marker.FilterValues.PinFilterValues[
                        3 * rowNumbers
                      ].key
                    : this.props.marker.FilterValues.PinFilterValues[
                        3 * rowNumbers
                      ].value}
                </Label>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div style={{ display: "" }}>
                <Label className={"colTitle"} style={{ margin: 0, padding: 0 }}>
                  {
                    this.props.marker.FilterValues.PinFilterValues[
                      3 * rowNumbers + 1
                    ].filterName
                  }
                </Label>
                <Label className={"colValue"} style={{ margin: 0, padding: 0 }}>
                  {this.props.marker.FilterValues.PinFilterValues[
                    3 * rowNumbers + 1
                  ].key != undefined
                    ? this.props.marker.FilterValues.PinFilterValues[
                        3 * rowNumbers + 1
                      ].key
                    : this.props.marker.FilterValues.PinFilterValues[
                        3 * rowNumbers + 1
                      ].value}
                </Label>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  private _table = () => {
    return (
      <div>
        {this.props.marker.details.metadata.map((data: any) => {
          if (data.type == "highlight | table | row") {
            return (
              <div className="table">
                <Label>{data.content.title}</Label>
                <Label>{data.content.body}</Label>
              </div>
            );
          }
        })}
      </div>
    );
  };

  private _columns = () => {
    return (
      <div>
        {this.props.marker.details.metadata.map((data: any) => {
          if (data.type == "highlight | columns | row") {
            return (
              <div className="table">
                <Label>{data.content.title}</Label>
                <Label>{data.content.body}</Label>
              </div>
            );
          }
        })}
      </div>
    );
  };

  private _gallery = () => {
    return (
      <div className="wrapper">
        {this.props.marker.details.Images.map((image: any, index: number) => {
          return (
            <div className={"box"}>
              <img
                className="gallery"
                src={image}
                onClick={() => {
                  this.setState({ currentIndex: index });
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  private _closeDialog = (): void => {
    this.setState({ hideDialog: true });
    this.props.closeCallback();
  };
}
