export class Utils {
  private static _isMobile: boolean;

  public static setIsMobile = (value: boolean) => {
    Utils._isMobile = value;
  };

  public static getIsMobile = (): boolean => {
    return Utils._isMobile;
  };
}
